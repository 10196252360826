
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { mrkpModuleMixin } from '../../mixins/markupModuleMixin'
import * as rules from '../../rules/rules'
import OnOffInput from '../KeystoneV2/tools/OnOffInput.vue'
import { $BEESWAX, $DBM, $THETRADEDESK, $YOUTUBE } from '../../../config/dspConfig'
import HelpTooltip from '@/components/Common/HelpTooltip.vue'
import { MarkupModuleState } from '../../../types/instruction_type'
import { KpiValue } from '../../../types/brief_enum'
import _ from 'lodash'

@Component({
  components: {
    OnOffInput,
    HelpTooltip
  }
})
export default class MarkupModule extends mixins(mrkpModuleMixin) {
  rules = rules

  mounted () {
    this.setUp()
    if (this.markupModule) {
      this.disableMarkupModuleIfKpiReach(this.insGetterSetter.trueKpiToOptimize)
      this.$emit('markupModuleLoaded')
    }
  }

  getModel () {
    return this.markupModule
  }

  @Watch('markupModule', { deep: true })
  onMarkupModuleChanged (value: any, oldValue: any) {
    this.$emit('update:model', value)
  }

  errorMessageKeywords () {
    let errors = []
    if (this.keystoneSettingSiege && this.keystoneSettingSiege.prodAddServingFees && this.markupModule.state !== 'off' &&
      !this.markupModule.noKeywords &&
      (!Array.isArray(this.markupModule.markupFeeDesignationKeywords) || !this.markupModule.markupFeeDesignationKeywords.length)) {
      errors.push('markupFeeDesignationKeywords should be filled when add_serving_fees value is equal to 1 or ' +
        'you should check no_keywords checkbox.')
    }
    return errors
  }

  // GETTERS

  get isBeeswax () {
    return this.dsp === $BEESWAX
  }

  get isTheTradeDesk () {
    return this.dsp === $THETRADEDESK
  }

  get isDisableChoiceMarkupModule () {
    return this.isBeeswax || (this.isSurcoucheForm && this.insGetterSetter.markupManagementIsInheritedFromSeat) ||
      (this.isKpiReach && ![$DBM, $YOUTUBE].includes(this.dsp))
  }

  get isKpiReach () {
    return this.insGetterSetter.trueKpiToOptimize === 'reach'
  }

  get itemsDesignationKeywords () {
    return this.markupModule.markupFeeDesignationKeywords
      ? this.markupModule.markupFeeDesignationKeywords
      : []
  }

  get isDisabledNoKeywords (): boolean {
    return !!(!this.isActiveKeywords || (this.markupModule.markupFeeDesignationKeywords && this.markupModule.markupFeeDesignationKeywords.length))
  }

  get computedActionItems () {
    if (
      ((this.isUserDebugger) &&
        (this.dsp === $DBM) &&
        (this.markupModule.state === 'custom') &&
        (this.markupModule.modificationMode === 'automatic') &&
        (this.markupModule.granularity === 'insertion_order_id')) ||
      (this.markupModule.action === 'media_savings')
    ) {
      let items = _.cloneDeep(this.actionItems)
      items.push({
        text: 'Media Savings',
        value: 'media_savings'
      })
      console.log(items)
      return items
    }
    return this.actionItems
  }

  getConvertedMarkupModule () {
    return this.markupModule.getObjectForApi()
  }

  inheritedStateIs (state: MarkupModuleState) {
    return this.insGetterSetter && this.insGetterSetter.markupManagementIsInheritedFromSeat &&
      this.inheritedMarkupModule && this.inheritedMarkupModule.state === state
  }

  @Watch('insGetterSetter.markupManagementIsInheritedFromSeat')
  onMarkupManagementIsInheritedFromSeatChange (value: boolean) {
    if (this.inheritedSiege != null && value) {
      this.setUpSurcoucheInheritedMarkupModule()
    }
  }

  @Watch('insGetterSetter.trueKpiToOptimize')
  disableMarkupModuleIfKpiReach (val: KpiValue) {
    if ((val === 'reach') && ![$DBM, $YOUTUBE].includes(this.dsp)) {
      this.removeMarkupModuleSeatInheritance()
      this.markupModule.state = 'off'
    }
  }
}
